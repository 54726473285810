/* Table header setup */
.sent-table {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border: none;
}

.sent-table > .rt-table > .rt-thead {
	box-shadow: none;
	border-bottom: 1px solid #e7eaf3;
}

.sent-table > .rt-table > .rt-thead > .rt-tr > .rt-th {
	background-color: #eff1f7;
	height: 32px;
	text-align: left;
	font-weight: 500;
	font-size: 15px;
	line-height: 24px;
	color: #AC80FF;
	border-right: none;
}

.sent-table > .rt-table > .rt-thead > .rt-tr > .rt-th:nth-child(1), .sent-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:nth-child(1) {
	padding-left: 15px;
}

/* Table row setup */
.sent-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td {
	height: 32px;
	font-size: 15px;
	border-right: none;
}

/* Table pagination setup */
.-pagination {
	box-shadow: none !important;
	border-top-width: 1px !important;
	border-top-color: #e7eaf3 !important;
}

/* Row sizing setup */
.sent-table > .rt-table > .rt-tbody, .sent-table > .rt-table > .rt-thead {
	min-width: 1080px !important;
}

.sent-table > .rt-table > .rt-thead > .rt-tr > .rt-th:nth-child(1), .sent-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:nth-child(1) {
	min-width: 180px;
}

.sent-table > .rt-table > .rt-thead > .rt-tr > .rt-th:nth-child(2), .sent-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:nth-child(2) {
	min-width: 450px;
	white-space: nowrap;
}

.sent-table > .rt-table > .rt-thead > .rt-tr > .rt-th:nth-child(3), .sent-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:nth-child(3) {
	min-width: 450px;
}

/* Custom styling for PST table */
.token-table > .rt-table > .rt-thead > .rt-tr > .rt-th:nth-child(1), .token-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:nth-child(1) {
	min-width: 450px;
}

.token-table > .rt-table > .rt-thead > .rt-tr > .rt-th:nth-child(2), .token-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:nth-child(2) {
	min-width: 215px;
}

.token-table > .rt-table > .rt-thead > .rt-tr > .rt-th:nth-child(3), .token-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:nth-child(3) {
	min-width: 215px;
}

.token-table > .rt-table > .rt-thead > .rt-tr > .rt-th:nth-child(4), .token-table > .rt-table > .rt-tbody > .rt-tr-group > .rt-tr > .rt-td:nth-child(4) {
	min-width: 200px;
}

.pstInquiry {
	display: inline-block;
	font-size: 14px;
	margin-block-start: -3px;
	background-color: transparent;
	border: 1px solid #AC80FF;
	border-radius: 5px;
	color: #AC80FF;
	font-weight: 600;
	cursor: pointer;
	transition: 75ms ease-in-out;
}

.pstInquiry:hover {
	background-color: #AC80FF;
	color: #fff;
}

.pstInquiry:focus {
	outline: none;
}

.pstInquiry > i {
	padding-right: 4px;
	font-size: 13px;
	transform: translateY(-0.5px);
}
