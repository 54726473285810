.mail-view {
	width: calc(100% - 70px);
	height: calc(100% - 70px);
	background-color: #fff;
	margin: 15px;
	border-radius: 5px;
	box-shadow: 0 0 35px rgba(127,150,174,.125);
	border: 1px solid #e7eaf3;
	padding: 20px;
	max-width: 750px;
}

.mail-view > div:nth-child(1) {
	height: 90px;
	border-bottom: 1px solid #e7e7e7;
}

.mail-view-loading {
	border-bottom: none !important;
	text-align: center;
	display: inline-block;
	height: 50px !important;
	width: 100%;
	transform: translateY(calc((100vh - 175px) / 2));
}

.mail-view-loading > i {
	font-size: 25px;
	color: #bec1c9;
}

.mail-view-loading > h5 {
	margin-block-start: 10px;
	margin-block-end: 0px;
	color: #6b6d72;
}

.mail-view > div:nth-child(1) > div {
	width: 100%;
	display: inline-block;
	vertical-align: top;
}

.mail-view > div:nth-child(1) > div:nth-child(1) {
	height: 55px;
}

.mail-view > div:nth-child(1) > div:nth-child(1) > div {
	display: inline-block;
	vertical-align: top;
	height: 100%;
}
.mail-view > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) {
	width: 55px;
}

.mail-view > div:nth-child(1) > div:nth-child(1) > div:nth-child(1) > img {
	height: 40px;
	margin: 5.5px;
	border-radius: 50%;
	border: 2px solid #1a1a1a;
}

.mail-view > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
	width: calc(100% - 65px);
	padding-left: 10px;
}

.mail-view > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > span {
	width: 100%;
	font-size: 14px;
	max-width: 95%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	margin-block-start: -2px;
}

.mail-view > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > span:nth-child(1) {
	margin-block-start: 9px;
}

.mail-view > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > span > a {
	text-decoration: none;
	color: #AC80FF;
	border-bottom: 1px dotted #AC80FF;
	display: inline-block;
	font-size: 13px;
	vertical-align: top;
	transition: 50ms ease-in-out;
}

.mail-view > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) > span > a:hover {
	opacity: 0.7;
}

.mail-view > div:nth-child(1) > div:nth-child(2) {
	height: 35px;
}

.mail-view > div:nth-child(1) > div:nth-child(2) > h3 {
	font-size: 15px;
	margin-block-start: 10px;
	margin-block-end: 0px;
	display: inline-block;
	max-width: calc(98% - 10px);
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
	height: 20px;
}

.mail-view > div:nth-child(2) {
	height: calc(100% - 130px);
}

.mail-view > div:nth-child(2) > div img {
	max-width: 100%;
}

.mail-view > div:nth-child(2) > div {
	max-height: 94%;
	overflow: auto;
}

.mail-view > div:nth-child(3) {
	height: 50px;
	padding-top: 5px;
	border-top: 1px solid #e7e7e7;
}

.mail-view > div:nth-child(3) > div {
	display: inline-block;
	height: 100%;
	vertical-align: top;
}

.mail-view > div:nth-child(3) > div:nth-child(1) {
	width: 220px;
}

.mail-view > div:nth-child(3) > div:nth-child(1) > span {
	display: inline-block;
	width: 100%;
	font-size: 14px;
	margin-block-start: 3px;
}

.mail-view > div:nth-child(3) > div:nth-child(1) > span > span {
	margin-left: 7px;
	background-color: #eeeeee;
	padding: 1px 6px;
	border-radius: 5px;
	vertical-align: middle;
}

.mail-view > div:nth-child(3) > div:nth-child(2) {
	width: calc(100% - 220px);
	text-align: right;
}

.mail-view > div:nth-child(3) > div:nth-child(2) > button {
	display: inline-block;
	font-size: 15px;
	border: none;
	padding: 7px 18px;
	margin: 6.5px;
	border-radius: 25px;
	background-color: transparent;
	border: 2px solid #AC80FF;
	transition: 50ms ease-in-out;
	cursor: pointer;
}

.mail-view > div:nth-child(3) > div:nth-child(2) > button:focus {
	outline: none;
}

.mail-view > div:nth-child(3) > div:nth-child(2) > button:nth-of-type(1):hover {
	background-color: #AC80FF;
	color: #fff;
}

.mail-view > div:nth-child(3) > div:nth-child(2) > button:nth-of-type(2) {
	background-color: #AC80FF;
	color: #fff;
}

.mail-view > div:nth-child(3) > div:nth-child(2) > button:nth-of-type(2):hover {
	opacity: 0.7;
}


.mail-view > div:nth-child(3) > div:nth-child(2) > button > i {
	margin-right: 10px;
}

.no-mail-selected {
	display: inline-block;
	width: 100%;
	text-align: center;
	height: 90px;
	transform: translateY(calc((100vh - 145px) / 2));
}

.no-mail-selected > img {
	height: 60px;
}

.no-mail-selected > h3 {
	font-size: 18px;
	margin-block-start: 5px;
	color: rgb(133, 133, 133);
}

@media screen and (max-width: 570px) {
	.mail-view {
		height: auto;
	}
	.mail-view > div:nth-child(3) {
		height: 85px;
	}
	.mail-view > div:nth-child(3) > div {
		height: 50px;
		width: 100% !important;
	}
	.mail-view > div:nth-child(3) > div:nth-child(2) {
		text-align: center;
	}
	.mail-view > div:nth-child(3) > div:nth-child(2) > button {
		font-size: 13px;
	}
	.mail-view-loading {
		transform: none;
		padding-top: 10px;
	}
}
